<template>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
      <div class="card-header border-0 pt-6">
        <div class="card-title" style="width:100%;">
          <h2>Liste des Producteurs et des Fournisseurs</h2>
        </div>
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList && state.myList.length == 0"
          class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">Aucune ligne à afficher</h4>
            <span>Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.</span>
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :rows-per-page="50"
          empty-table-text="Aucune ligne à afficher."
          :key="state.loadingDatatable"
          class="table-small-td"
        >
          <template v-slot:cell-act_c_categorie="{ row: data }">
            <span class='text-gray-500 fs-7'>{{ returnType(data.act_ark_n_type) }}</span><br>
            <span class='text-gray-800 fs-7'>{{ data.act_c_categorie }}</span>
          </template>

          <template v-slot:cell-act_c_raison_sociale="{ row: data }">
            <span v-if="data.act_ark_n_type == 2">{{ data.act_c_raison_sociale }} {{ data.act_ark_c_nom2 }}</span>
            <div v-else>
              <div class="text-dark"><b>{{ data.act_c_raison_sociale }}</b></div>
              <div class="text-gray-500 fs-7" v-if="data.act_ark_c_nom2">{{ data.act_ark_c_nom2 }}</div>
              <div class="text-gray-500 fs-7" v-if="data.act_ark_c_nom3">{{ data.act_ark_c_nom3 }}</div>
              <div class="text-gray-500 fs-7" v-if="data.act_ark_c_nom4">{{ data.act_ark_c_nom4 }}</div>
            </div>
          </template>

          <template v-slot:cell-act_c_adr="{ row: data }">
            <div class="fs-7 text-gray-500">
              <span>{{ data.act_c_adr }}</span><br>
              <span>{{ data.act_ark_c_cp }}</span>
              <span>&nbsp;{{ data.act_ark_c_ville }}</span>
              <span>&nbsp;{{ data.act_ark_c_pays }}</span>
            </div>
          </template>

          <template v-slot:cell-act_c_phone="{ row: data }">
            <div class="fs-7 text-gray-500">
              <div>{{ data.act_c_phone }}</div>
              <div class="text-blue">{{ data.act_c_mail }}</div>
            </div>
          </template>

          <template v-slot:cell-act_ark_n_code="{ row: data }">
            <span class="badge bg-blue fs-7 w-75px">{{ data.act_ark_n_code }}</span>
          </template>

          <template v-slot:cell-act_n_seq="{ row: data }">
            <div style='width:208px;'>
              <a class="btn btn-sm btn-custom-white mx-1" @click='displayActor(data)'>
                <span class="svg-icon svg-icon-3 svg-icon-primary">
                  <inline-svg src="media/icons/duotune/art/art005.svg"/> </span>
                  Modifier
              </a>
              </div>
          </template>

        </Datatable>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },
  emits: ["displayActor"],

  setup(props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zActeur: {},
    });
    const tableHeader = ref([
      { name: "Ref. Arkheia", key: "act_ark_n_code", sortable: false,},
      { name: "Catégorie", key: "act_c_categorie", sortable: false,},
      { name: "Raison sociale", key: "act_c_raison_sociale", sortable: false,},
      { name: "Adresse", key: "act_c_adr", sortable: false,},
      { name: "Coordonnées", key: "act_c_phone", sortable: false,},
      { name: "",  key: "act_n_seq", sortable: false,},
    ]);

    const tableFilters = ref([
      { name: "Catégorie", key: "act_c_categorie", label: "act_c_categorie", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zActeur = d;
    };

    const returnType = (type) => {
      let mType = ""
      switch (type) {
        case "1":
          mType = "Collectivité"
          break;
        case "2":
          mType = "Personne"
          break;
        case "3":
          mType = "Famille"
          break;
        default:
          mType = "Type inconnu"
      }
      return mType;
    }

    const displayActor = (d) => {
      emit("displayActor", d);
    }

    onMounted(async () => {

      let myListLiv = {} as any;

      myListLiv = await getAxios("/getSVProducteur");

      state.myList = myListLiv.results;
      state.initialMyList = myListLiv.results;

      state.zActeur = state.myList[0];

      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      returnType,
      displayActor
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
