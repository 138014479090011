
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },
  
  emits: ["displayUser"],

  setup( props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Service", key: "uti_c_service", sortable: false,},
      { name: "Rôle", key: "rol_n_seq", sortable: false,},
      { name: "",  key: "uti_n_seq_action", sortable: false,},
    ]);

    const tableFilters = ref([
      { name: "Statut", key: "uti_c_status", label: "uti_c_status", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zUser = d;
    };

    const resetMdp = (d) => {
      mAxiosApi
        .get("/reinitPassword/" + d.uti_n_seq)
        .then((d: any) => {
          ElNotification({ title: 'Succès', message: 'Mot de passe réinitialisé avec succès !', type: 'success', })
        })
    }

    onMounted(async () => {

      let myListLiv = {} as any;

      myListLiv = await getAxios("/getSVUsers");

      state.myList = myListLiv.results;


      state.myList.forEach(element => {
        if (element.producteur) {
          element.producteur = element.producteur.split(",");  //.map(function(e){return parseInt(e,10)})
        }
      });


      state.initialMyList = myListLiv.results;

      state.zUser = state.myList[0];

      state.loaderEnabled = false;
    });

    const displayUser = (d) => {
      emit("displayUser", d);
    }


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      displayUser,
      resetMdp
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
