<template>
  <transition name="fade">
      <el-row :gutter="10" class="mt-10" style="width: 100%">
        <el-col :span="24">

          <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
            <div class="card-header border-0 pt-6">
              <div class="card-title" style="width:100%;">
                <h2>Liste des utilisateurs</h2>
              </div>
              <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
              ></SearchAndFilter>
            </div>
            <div class="card-body pt-0">
              <div
                v-if="state.myList.length == 0"
                class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
              >
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="media/icons/duotune/general/gen048.svg" />
                </span>
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-dark">Aucune ligne à afficher</h4>
                  <span>Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.</span>
                </div>
              </div>
              <Datatable
                v-if="state.myList && state.myList.length > 0"
                :table-data="state.myList"
                :table-header="tableHeader"
                :enable-items-per-page-dropdown="true"
                :rows-per-page="50"
                empty-table-text="Aucune ligne à afficher."
                :key="state.loadingDatatable"
                class="table-small-td"
              >
                <template v-slot:cell-uti_c_prenom="{ row: data }">{{ data.uti_c_prenom }} {{ data.uti_c_nom }}</template>
                <template v-slot:cell-uti_c_mail="{ row: data }">
                  <div class="fs-7 text-gray-500">
                    <div>{{ data.uti_c_phone }}</div>
                    <div class="text-blue">{{ data.uti_c_mail }}</div>
                  </div>
                </template>
                <template v-slot:cell-rol_n_seq="{ row: data }">{{ data.rol_c_libelle }}</template>
                <template v-slot:cell-uti_c_service="{ row: data }">{{ data.uti_c_service }}</template>
                <template v-slot:cell-uti_c_status="{ row: data }">
                  <span class="badge badge-success" v-if="data.uti_c_status=='Compte actif'">{{ data.uti_c_status }}</span>
                  <span class="badge badge-danger" v-else>{{ data.uti_c_status }}</span>
                </template>
                <template v-slot:cell-uti_n_seq_action="{ row: data }">
                  <div style='width:310px;'>
                    <a class="btn btn-sm btn-custom-white mx-1" @click='displayUser(data)'>
                      <span class="svg-icon svg-icon-3 svg-icon-primary">
                        <inline-svg src="media/icons/duotune/art/art005.svg"/> </span>
                        Modifier
                    </a>

                    <el-popconfirm  confirm-button-text="Oui, réinitialiser le mot de passe"  cancel-button-text="Non"  title="Réinitialiser le mot de passe et renvoyer un mail à l'utilisateur ?"  @confirm="resetMdp(data)"  >
                      <template #reference>  
                        <a class="btn btn-sm btn-custom-white mx-1">
                          <span class="svg-icon svg-icon-2 svg-icon-danger">
                            <inline-svg src="media/icons/duotune/general/gen047.svg"/> </span>
                            Mot de passe
                        </a>
                      </template>
                    </el-popconfirm>

                  </div>
                </template>
              </Datatable>
            </div>
          </div>

        </el-col>
      </el-row>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },
  
  emits: ["displayUser"],

  setup( props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Service", key: "uti_c_service", sortable: false,},
      { name: "Rôle", key: "rol_n_seq", sortable: false,},
      { name: "",  key: "uti_n_seq_action", sortable: false,},
    ]);

    const tableFilters = ref([
      { name: "Statut", key: "uti_c_status", label: "uti_c_status", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zUser = d;
    };

    const resetMdp = (d) => {
      mAxiosApi
        .get("/reinitPassword/" + d.uti_n_seq)
        .then((d: any) => {
          ElNotification({ title: 'Succès', message: 'Mot de passe réinitialisé avec succès !', type: 'success', })
        })
    }

    onMounted(async () => {

      let myListLiv = {} as any;

      myListLiv = await getAxios("/getSVUsers");

      state.myList = myListLiv.results;


      state.myList.forEach(element => {
        if (element.producteur) {
          element.producteur = element.producteur.split(",");  //.map(function(e){return parseInt(e,10)})
        }
      });


      state.initialMyList = myListLiv.results;

      state.zUser = state.myList[0];

      state.loaderEnabled = false;
    });

    const displayUser = (d) => {
      emit("displayUser", d);
    }


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      displayUser,
      resetMdp
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
